.AddCorner
    background-color: #d4d4d4
    aspect-ratio: 1 / 1
    border-radius: 20px 0px 15px 0px
    max-width: 20px
    padding: 20px
    text-align: center
    align-self: flex-end
    margin-top: auto
    cursor: pointer

.AddCorner:hover
    background-color: #c2c2c2