.Wrapper
    flex-direction: row
    justify-content: center
    background-color: #fff
    height: 100%
    margin: 0 auto

.ElementWrapper
    display: flex
    justify-content: right
    max-width: 1060px
    width: 100%
    padding: 0px 10px 0px 10px
    // background-color: tomato