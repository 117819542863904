.Navbar
    display: flex
    justify-content: center
    align-items: center
    height: 50px
    padding: 10px 0px 10px 0px
    margin: 0
    background: #88FFD5
    position: -webkit-sticky
    position: sticky
    top: -1px
    z-index: 100

#FavoritesNavbarPlacement
    border-radius: 5px
    background-color: #f3f3f3
    margin-left: 10px
    padding: 9px