.ProductContainer
    aspect-ratio: 1/1
    max-width: 250px
    width: 100%
    margin: 9px

.Product
    display: flex
    flex-direction: column
    border-radius: 0px 0px 20px 0px
    aspect-ratio: 1/1
    max-width: 250px
    width: 100%
    font-size: 16px
    border: 1px solid #d4d4d4

/* @media only screen and (min-device-width: 250px) and (max-device-width: 499px)
    .Product
        max-width: 499px
        width: 100% */

#FavoritesPlacement
    position: absolute
    margin-left: 195px
    padding: 10px

.ProductContent
    padding: 25px 25px 0px 25px

.Product img
    display: block
    max-height: 120px
    height: auto
    width: auto
    margin: auto

.Product:hover
    cursor: pointer