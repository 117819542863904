#Sortbar
    display: flex
    width: 100%
    height: 40px
    justify-content: center
    margin-bottom: 10px
    padding: 10px 0px 10px 0px

#Sortbar select
    padding: 10px
    width: 150px

@media screen and (max-width: 552px)
    #Sortbar
        background-color: #f5f5f5
    
    #Sortbar select
        width: 250px

    .ElementWrapper
        display: flex
        justify-content: center