#CartContainer
  width: 1080px

#CartOverview
  display: flex
  flex-direction: column

#CartOverview .row
  height: 100px
  width: 100%
  display: flex
  justify-content: space-around
  align-items: center

#CartOverview .row .cell
  min-width: 50px
  max-width: 150px
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  margin-left: 5px

#CartOverview .row .info
  min-width: 50px
  max-width: 450px
  width: 100%
  display: flex
  justify-self: space-around
  align-items: center

#CartOverview .row:nth-child(even)
  background-color: #f3f3f3

#CartOverview .row img
  max-width: 100%
  max-height: 70px

@media screen and (max-width: 500px)
  #CartOverview .row
    height: 100%

  #CartOverview .cell
    display: flex
    justify-content: center

  #CartOverview .row .info
    flex-direction: column
    max-width: 150px
    height: 100%
    padding: 10px
