.App
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  background-color: #fdf8f2

.App-header
  background-color: #fdf8f2
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  font-size: calc(10px + 2vmin)
  color: black

h4
  width: 100%
  padding-left: 10px
  margin: 40px 0 0 0