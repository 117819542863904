body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  -webkit-tap-highlight-color: transparent

input,
textarea,
button,
select,
a
  -webkit-tap-highlight-color: rgba(0,0,0,0)

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace