.ProductInfo
    display: flex
    max-width: 1080px
    width: 100%
    padding: 5px
    height: auto

#Title
    display: flex
    justify-content: space-between
    margin-bottom: 20px

#ProductImage
    min-width: 50%

.ProductInfo img
    display: flex
    margin: auto
    width: 90%
    height: auto
    margin-bottom: 10px

.ph
    font-size: x-large
    font-weight: bold
    margin-bottom: 5px


#NutritionInfo
    display: flex
    flex-direction: column
    padding: 0px 10px 0px 10px
    width: 90%

#NutritionInfo .row
  height: 50px
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between

#NutritionInfo .row .cell
    width: 100%
    display: flex
    margin-left: 5px

#NutritionInfo .row:nth-child(even)
  background-color: #f3f3f3

#Details
    width: 50%
    padding: 0px 10px


@media screen and (max-width: 600px)
    .ProductInfo
        display: flex
        flex-direction: column

    #Details
        width: 100%

    #NutritionInfo
        width: auto
    
    #Title
        flex-direction: column
        align-items: center
        padding: 0px 20px 0px 0px

    .ph
        text-align: center
        margin-bottom: 10px