.Content
    display: flex
    justify-content: center
    min-height: calc(100vh - 401px)
    padding: 20px 0px 40px 0px
    overflow-y: scroll
    -ms-overflow-style: none  /* IE and Edge */
    scrollbar-width: none  /* Firefox */
    align-items: center

/* Hide scrollbar for Chrome, Safari and Opera */
.Content::-webkit-scrollbar
    display: none