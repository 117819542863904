.Footer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-color: #88FFD5
    width: 100%
    min-height: 200px
    height: 100%
    text-align: center
    top: 100vh