.AddText
    display: flex
    justify-content: center
    align-self: center
    background-color: #d4d4d4
    border-radius: 5px
    padding: 20px 0px 20px 0px 
    text-align: center
    cursor: pointer
    width: 120px

.AddText:hover
    background-color: #c2c2c2

@media screen and (max-width: 600px)
    .AddText
        width: 100%